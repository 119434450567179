import { ActionTree, MutationTree, GetterTree, Module } from 'vuex'

import type { RootState } from '@/store/index'
import { showErrorMessage } from '@/utils/toast-message'

import StatisticsApi from '@/api/statistics'

import {
	StatisticsGenerationEventType,
	StatisticsGenerationSignalREvent,
	StatisticsCreatedData,
	StatisticsNotCreatedData,
	StatisticsLink
} from '@/api/statistics/models/Statistic'
import {
	StatisticsState,
	Actions,
	StatisticsActionTypes,
	StatisticsMutationTypes,
	Mutations,
	Getters,
	StatisticsGettersType
} from './types'
import { NotificationsActionTypes } from '../notifications/types'

export const statisticsState: StatisticsState = {
	isStatisticsLoading: false,
	isMetricsLoading: false,
	metricsItems: []
}

const actions: ActionTree<StatisticsState, RootState> & Actions = {
	async [StatisticsActionTypes.GENERATE_STATISTICS](
		{ dispatch },
		{ accountId, from, to, onProcess, onSuccess, onError }
	) {
		try {
			try {
				onProcess('Triggering statistics generation...')

				await dispatch(NotificationsActionTypes.INIT_CONNECTION, { candidateAccountId: accountId })

				await StatisticsApi.triggerStatisticsGeneration(accountId, from, to)

				onProcess('The statistics generation process has started... Please wait here for the results')
			} catch (e) {
				dispatch(NotificationsActionTypes.STOP_CONNECTION)

				onError(e)

				return
			}

			dispatch(NotificationsActionTypes.SUBSCRIBE, {
				callback: (event: StatisticsGenerationSignalREvent) => {
					if (event.Type === StatisticsGenerationEventType.StatisticsNotCreatedEvent) {
						onError((event.Data as StatisticsNotCreatedData).Message)
					} else if (event.Type === StatisticsGenerationEventType.StatisticsCreatedEvent) {
						const data = event.Data as StatisticsCreatedData

						onProcess('Almost ready, now getting your reports download links...')

						Promise.all([
							dispatch(StatisticsActionTypes.GET_STATISTICS_LINK, {
								accountId,
								fileName: data.CampaignsFile
							}),
							dispatch(StatisticsActionTypes.GET_STATISTICS_LINK, {
								accountId,
								fileName: data.SurveysFile
							})
						]).then((links: StatisticsLink[]) => {
							onSuccess(links)
						})
					}

					setTimeout(() => {
						dispatch(NotificationsActionTypes.STOP_CONNECTION)
					}, 1000)
				}
			})
		} catch (e) {
			onError(e)
		}
	},

	async [StatisticsActionTypes.GET_STATISTICS_LINK](_, { accountId, fileName }) {
		try {
			const response = await StatisticsApi.getStatisticsLink(accountId, fileName)

			const blob = new Blob([response.data], { type: 'text/csv' })

			const url = window.URL.createObjectURL(blob)

			return {
				fileName,
				fileLink: url
			}
		} catch (error) {
			showErrorMessage(error)

			return null
		}
	},

	async [StatisticsActionTypes.GET_METRICS]({ commit }, { accountId, type, from, campaignId = '', to = '' }) {
		try {
			commit(StatisticsMutationTypes.SET_METRICS_LOADING_STATUS, true)

			const metrics = await StatisticsApi.getMetrics(accountId, type, from, campaignId, to)

			commit(StatisticsMutationTypes.SET_METRICS, metrics)

			commit(StatisticsMutationTypes.SET_METRICS_LOADING_STATUS, false)
		} catch (error) {
			commit(StatisticsMutationTypes.SET_METRICS_LOADING_STATUS, false)

			showErrorMessage(error)
		}
	}
}

const mutations: MutationTree<StatisticsState> & Mutations = {
	[StatisticsMutationTypes.SET_METRICS](moduleState, metrics) {
		moduleState.metricsItems = metrics
	},

	[StatisticsMutationTypes.SET_STATISTICS_LOADING_STATUS](moduleState, status) {
		moduleState.isStatisticsLoading = status
	},

	[StatisticsMutationTypes.SET_METRICS_LOADING_STATUS](moduleState, status) {
		moduleState.isMetricsLoading = status
	}
}

export const getters: GetterTree<StatisticsState, RootState> & Getters = {
	[StatisticsGettersType.GET_TOTAL_SUCCESS_VOTERS](moduleState) {
		return moduleState.metricsItems.reduce((total, item) => total + item.SuccessfulContacts, 0)
	},
	[StatisticsGettersType.GET_ACTIVIST_NUMBER](moduleState) {
		const uniqueVoters = new Set(moduleState.metricsItems.map((item) => item.UserId))

		return uniqueVoters.size
	}
}

export const store: Module<StatisticsState, RootState> = {
	state: statisticsState,
	getters,
	mutations,
	actions
}
