export interface StatisticsLink {
	fileName: string
	fileLink: string
}

export enum StatisticsGenerationEventType {
	StatisticsCreatedEvent = 'StatisticsCreatedEvent',
	StatisticsNotCreatedEvent = 'StatisticsNotCreatedEvent'
}

export interface StatisticsCreatedData {
	CampaignsFile: string
	SurveysFile: string
}

export interface StatisticsNotCreatedData {
	Message: string
}

export interface StatisticsGenerationSignalREvent {
	Type: StatisticsGenerationEventType
	Data: StatisticsCreatedData | StatisticsNotCreatedData
}
