<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue'

import { StatisticsLink } from '@/api/statistics/models/Statistic'
import { useStore } from '@/store'
import { AuthGettersType } from '@/store/modules/auth/types'
import { StatisticsActionTypes } from '@/store/modules/statistics/types'
import { useRoute } from 'vue-router'

const store = useStore()

const route = useRoute()

const props = defineProps<{
	candidateAccountId?: string
	reportFileName?: string
}>()

const statisticsLink = ref<StatisticsLink | null>(null)

const statisticsMsg = ref<string | null>(null)

const statisticsReportDownloadLink = ref<HTMLLinkElement | null>(null)

const userName = computed(() => store.getters[AuthGettersType.GET_USER_NAME])

const selectedAccount = computed(() => store.state.candidateAccount.selectedCandidateAccount)

const candidateAccounts = computed(() => store.state.candidateAccount.candidateAccounts)

const isAuthorized = computed(() => store.state.auth.isAuthorized)

const currentUserRole = computed(() => store.getters[AuthGettersType.GET_USER_ROLE])

const isStatisticsLoading = computed(() => store.state.statistics.isStatisticsLoading)

const switchAccountLinkQuery = computed(() => {
	if (!props.candidateAccountId || !props.reportFileName || props.candidateAccountId === selectedAccount.value?.Id) {
		return {}
	}

	return { redirect_url: route.fullPath, candidate_account: props.candidateAccountId }
})

onMounted(() => {
	if (props.candidateAccountId && props.reportFileName) {
		if (props.candidateAccountId !== selectedAccount.value?.Id) {
			// user is logged in under another candidate account
			statisticsMsg.value =
				"You're trying to get the statistics report for another candidate account. If you have access to it - please click the button above 👆 to switch over to it (you will be asked for a password)"

			return
		}

		store
			.dispatch(StatisticsActionTypes.GET_STATISTICS_LINK, {
				accountId: props.candidateAccountId,
				fileName: props.reportFileName
			})
			.then((link) => {
				statisticsLink.value = link

				return nextTick()
			})
			.then(() => {
				statisticsReportDownloadLink.value!.click()
			})
	}
})
</script>

<template>
	<AppLayout :is-loading="!isAuthorized || isStatisticsLoading" :is-solid-background="true">
		<section class="dashboard">
			<div class="container">
				<div class="dashboard__content">
					<h1 automation-tag="welcome-label">Welcome{{ userName ? `, ${userName}` : '!' }}</h1>
					<h2 automation-tag="account-role-label">
						{{ selectedAccount?.Name }} <span>{{ currentUserRole }}</span>
					</h2>

					<router-link
						v-if="candidateAccounts?.length > 1"
						automation-tag="change-candidate-button"
						class="btn"
						:to="{ name: 'login', query: switchAccountLinkQuery }"
					>
						Login to another candidate account
					</router-link>

					<div class="statistics__form-links" v-if="statisticsMsg != null">
						<h3 automation-tag="links-section">
							{{ statisticsMsg }}
						</h3>
					</div>

					<div class="statistics__form-links" v-if="statisticsLink != null">
						<h3 automation-tag="links-section">
							Your report file download started automatically... If not, please click this link:
						</h3>

						<a
							ref="statisticsReportDownloadLink"
							automation-tag="link"
							:href="statisticsLink.fileLink"
							:download="statisticsLink.fileName + '.csv'"
							v-text="statisticsLink.fileName + '.csv'"
						></a>
					</div>
				</div>
			</div>
		</section>
	</AppLayout>
</template>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.dashboard {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background: url('/images/welcome.png') bottom center no-repeat;
	background-size: auto 250px;
	padding: 0 150px;

	* {
		box-sizing: border-box;
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 200px;

		h1 {
			font-size: 83px;
			text-align: left;
			margin-bottom: 12px;
			color: $sf-blue;
			text-transform: uppercase;
			font-family: Proxima-Light;
			background: $light-blue;
		}

		h2 {
			font-size: 48px;
			text-align: left;
			color: $dark;
			font-family: Montserrat-Light;
			background: $light-blue;
			text-transform: uppercase;

			span {
				margin-left: 5px;
				font-size: 36px;
				text-transform: uppercase;
				color: $blue;
				font-family: Montserrat-Bold;
			}
		}

		.btn {
			width: auto;
			display: flex;
			text-decoration: none;
			align-items: center;
			padding: 0 20px;
			margin-top: 12px;
		}
	}

	.statistics {
		&__form {
			max-width: 670px;
			width: 100%;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			margin: 0px 20px 20px;

			&-links {
				margin-top: 32px;
				margin-bottom: 30px;

				h3 {
					text-align-last: left;
					font-size: 18px;
					font-family: Montserrat-Medium;
					margin-bottom: 20px;
				}

				a {
					display: block;
					color: rgb(22, 133, 223);
					font-size: 16px;
					font-family: Montserrat-Medium;
					text-decoration: none;
					margin-bottom: 15px;
				}
			}
		}
	}
}
</style>
