import { ActionContext, CommitOptions, DispatchOptions, Store as VuexStore } from 'vuex'

import type { RootState } from '@/store/index'
import { MetricItem } from '@/api/statistics/models/Metric'
import { StatisticsLink } from '@/api/statistics/models/Statistic'

export interface StatisticsState {
	isStatisticsLoading: boolean
	isMetricsLoading: boolean
	metricsItems: MetricItem[]
}

export enum StatisticsActionTypes {
	GENERATE_STATISTICS = 'GENERATE_STATISTICS',
	GET_STATISTICS_LINK = 'GET_STATISTICS_LINK',
	GET_METRICS = 'GET_METRICS'
}

export enum StatisticsMutationTypes {
	SET_METRICS = 'SET_METRICS',
	SET_METRICS_LOADING_STATUS = 'SET_METRICS_LOADING_STATUS',
	SET_STATISTICS_LOADING_STATUS = 'SET_STATISTICS_LOADING_STATUS'
}

export type Mutations<S = StatisticsState> = {
	[StatisticsMutationTypes.SET_METRICS](moduleState: S, metrics: MetricItem[]): void
	[StatisticsMutationTypes.SET_METRICS_LOADING_STATUS](moduleState: S, status: boolean): void
	[StatisticsMutationTypes.SET_STATISTICS_LOADING_STATUS](moduleState: S, status: boolean): void
}

export type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<StatisticsState, RootState>, 'commit'>

export interface Actions {
	[StatisticsActionTypes.GENERATE_STATISTICS](
		{ commit, dispatch }: AugmentedActionContext,
		{
			accountId,
			from,
			to,
			onSuccess,
			onError,
			onProcess
		}: {
			accountId: string
			from: string
			to?: string
			onProcess: (message: string) => void
			onSuccess: (links: StatisticsLink[]) => void
			onError: (error?: any) => void
		}
	): void
	[StatisticsActionTypes.GET_STATISTICS_LINK](
		{ commit, state }: AugmentedActionContext,
		{ accountId, fileName }: { accountId: string; fileName: string }
	): void
	[StatisticsActionTypes.GET_METRICS](
		{ commit }: AugmentedActionContext,
		{
			accountId,
			type,
			from,
			campaignId,
			to
		}: { accountId: string; type: string; from: string; campaignId?: string; to?: string }
	): void
}

export enum StatisticsGettersType {
	GET_TOTAL_SUCCESS_VOTERS = 'GET_TOTAL_SUCCESS_VOTERS',
	GET_ACTIVIST_NUMBER = 'GET_ACTIVIST_NUMBER'
}

export type Getters<State = StatisticsState> = {
	[StatisticsGettersType.GET_TOTAL_SUCCESS_VOTERS](moduleState: State): number
	[StatisticsGettersType.GET_ACTIVIST_NUMBER](moduleState: State): number
}

export type StatisticsStore<S = StatisticsState> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'> & {
	commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
		key: K,
		payload: P,
		options?: CommitOptions
	): ReturnType<Mutations[K]>
} & {
	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
} & {
	getters: {
		[K in keyof Getters]: ReturnType<Getters[K]>
	}
}
