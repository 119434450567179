import * as signalR from '@microsoft/signalr'
import { ActionContext, CommitOptions, DispatchOptions, Store as VuexStore } from 'vuex'

import type { RootState } from '@/store/index'

export interface NotificationsState {
	signalRConnection: signalR.HubConnection | null
}

export enum NotificationsActionTypes {
	SUBSCRIBE = 'SUBSCRIBE',
	INIT_CONNECTION = 'INIT_CONNECTION',
	STOP_CONNECTION = 'STOP_CONNECTION'
}

export enum NotificationsMutationTypes {
	SET_SIGNALR_CONNECTION = 'SET_SIGNALR_CONNECTION'
}

export type Mutations<S = NotificationsState> = {
	[NotificationsMutationTypes.SET_SIGNALR_CONNECTION](moduleState: S, connection: signalR.HubConnection): void
}

export type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<NotificationsState, RootState>, 'commit'>

export interface Actions {
	[NotificationsActionTypes.SUBSCRIBE](
		{ commit, rootState }: AugmentedActionContext,
		{ callback }: { callback: (...args: any[]) => any }
	): void

	[NotificationsActionTypes.INIT_CONNECTION](
		{ commit, rootState }: AugmentedActionContext,
		{ candidateAccountId }: { candidateAccountId: string }
	): void

	[NotificationsActionTypes.STOP_CONNECTION]({ commit, rootState }: AugmentedActionContext): void
}

// export type Getters<State = NotificationsState> = {}

export type ContactListsStore<S = NotificationsState> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'> & {
	commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
		key: K,
		payload: P,
		options?: CommitOptions
	): ReturnType<Mutations[K]>
} & {
	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
}
// & {
// 	getters: {
// 		[K in keyof Getters]: ReturnType<Getters[K]>
// 	}
// }
