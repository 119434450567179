import { ActionTree, MutationTree, Module } from 'vuex'

import * as signalR from '@microsoft/signalr'

import type { RootState } from '@/store/index'

import { getFirebaseIdToken } from '@/utils/firebase'
import { NotificationsState, Actions, NotificationsActionTypes, NotificationsMutationTypes, Mutations } from './types'

export const contactListsState: NotificationsState = {
	signalRConnection: null
}

const actions: ActionTree<NotificationsState, RootState> & Actions = {
	async [NotificationsActionTypes.INIT_CONNECTION]({ commit }, { candidateAccountId }) {
		const signalRConnection = new signalR.HubConnectionBuilder()
			.withUrl(`${import.meta.env.VITE_API_NOTIFICATIONS_HOST}/candidateaccounts/${candidateAccountId}`, {
				withCredentials: false,
				async accessTokenFactory() {
					const token = await getFirebaseIdToken()

					return `${token}`
				}
			})
			.build()

		commit(NotificationsMutationTypes.SET_SIGNALR_CONNECTION, signalRConnection)

		await signalRConnection.start()
	},

	async [NotificationsActionTypes.SUBSCRIBE]({ state }, { callback }) {
		if (state.signalRConnection !== null) {
			state.signalRConnection.on('onNewNotification', callback)
		}
	},

	[NotificationsActionTypes.STOP_CONNECTION]({ state }) {
		if (state.signalRConnection) {
			state.signalRConnection.off('onNewNotification')

			state.signalRConnection.stop()
		}
	}
}

const mutations: MutationTree<NotificationsState> & Mutations = {
	[NotificationsMutationTypes.SET_SIGNALR_CONNECTION](moduleState, connection) {
		moduleState.signalRConnection = connection
	}
}

// export const getters: GetterTree<NotificationsState, RootState> & Getters = {}

export const store: Module<NotificationsState, RootState> = {
	state: contactListsState,
	// getters,
	mutations,
	actions
}
