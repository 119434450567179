<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'

import AppRoutes from '@/router/routes'
import { useStore } from '@/store'
import { AuthActionTypes, AuthGettersType } from '@/store/modules/auth/types'

import SignOutPrompt from '@/components/modals/SignOutPrompt.vue'
import { FilterCampaignType } from '@/api/campaign/Campaigns'
import { UserRole } from '@/api/candidate-account/models/UserRole'
import { getRemoteConfigValue } from '@/utils/firebase'

const router = useRouter()

const store = useStore()

const platformAppsEnabledFor = ref([] as string[])

const currentAccount = computed(() => store.state.candidateAccount.selectedCandidateAccount)

const isPlatformAppsEnabled = computed(() => {
	if (!currentAccount.value?.Id) return false

	return platformAppsEnabledFor.value.includes(currentAccount.value?.Id)
})

const currentUserRole = computed(() => store.getters[AuthGettersType.GET_USER_ROLE])

const isAdmin = computed(() => currentUserRole.value === UserRole.Admin)

const isCampaignManager = computed(() => currentUserRole.value === UserRole.CampaignManager)

const isPromptActive = ref(false)

function onSignOutTap() {
	isPromptActive.value = true
}

function closePrompt() {
	isPromptActive.value = false
}

async function logOut() {
	await store.dispatch(AuthActionTypes.LOG_OUT)

	router.push({ name: AppRoutes.login })
}

function onPromptResult(result: boolean) {
	closePrompt()

	if (result) {
		logOut()
	}
}

onMounted(() => {
	getRemoteConfigValue('platform_apps_enabled_for_candidates').then((value) => {
		if (!value) return

		platformAppsEnabledFor.value = JSON.parse(value.asString())
	})
})
</script>

<template>
	<nav>
		<router-link class="logo-link" automation-tag="app-logo" :to="{ name: AppRoutes.dashboard }">
			<img class="logo" src="/images/superfeed.png" />
		</router-link>

		<router-link class="home-link" automation-tag="home-button" :to="{ name: AppRoutes.dashboard }">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M9.29289 0.292893C9.68342 -0.0976311 10.3166 -0.0976311 10.7071 0.292893L17.7068 7.29254C17.7069 7.29266 17.707 7.29278 17.7071 7.29289L19.7071 9.29289C20.0976 9.68342 20.0976 10.3166 19.7071 10.7071C19.3166 11.0976 18.6834 11.0976 18.2929 10.7071L18 10.4142V19C18 19.5523 17.5523 20 17 20H12C11.4477 20 11 19.5523 11 19V15H9V19C9 19.5523 8.55228 20 8 20H3C2.44772 20 2 19.5523 2 19V10.4142L1.70711 10.7071C1.31658 11.0976 0.683417 11.0976 0.292893 10.7071C-0.0976311 10.3166 -0.0976311 9.68342 0.292893 9.29289L2.29289 7.29289C2.29296 7.29282 2.29303 7.29276 2.2931 7.29269L9.29289 0.292893ZM4 8.41421L10 2.41421L16 8.41421V18H13V14C13 13.4477 12.5523 13 12 13H8C7.44772 13 7 13.4477 7 14V18H4V8.41421Z"
					fill="#4F7CA6"
				/>
			</svg>

			<span>{{ currentAccount?.Name }}</span>
		</router-link>

		<section class="nav-category">
			<h3 class="nav-category__title">Campaign Tools</h3>

			<div class="nav-category__links">
				<!-- <router-link
					automation-tag="voter-contact-lists-menu-item"
					:to="{ name: 'voter-contact-lists', params: { accountId: currentAccount?.Id } }"
					>Voter Contact Lists</router-link
				> -->

				<router-link
					automation-tag="walk-campaigns-menu-item"
					:to="{
						name: AppRoutes.campaignsService,
						params: { type: FilterCampaignType.Walking.toLocaleLowerCase() }
					}"
					>Walk Campaigns</router-link
				>
				<router-link
					automation-tag="phone-campaigns-menu-item"
					:to="{
						name: AppRoutes.campaignsService,
						params: { type: FilterCampaignType.Phone.toLocaleLowerCase() }
					}"
					>Phone Campaigns</router-link
				>
				<router-link
					automation-tag="postcard-campaigns-menu-item"
					:to="{
						name: AppRoutes.campaignsService,
						params: { type: FilterCampaignType.Postcard.toLocaleLowerCase() }
					}"
					>Postcard Campaigns</router-link
				>
				<router-link
					automation-tag="sms-campaigns-menu-item"
					:to="{
						name: AppRoutes.campaignsService,
						params: { type: FilterCampaignType.Sms.toLocaleLowerCase() }
					}"
					>SMS Campaigns</router-link
				>

				<router-link
					automation-tag="custom-campaign-menu-item"
					:to="{
						name: AppRoutes.customCampaign
					}"
					>Custom Campaign</router-link
				>
			</div>
		</section>

		<section class="nav-category">
			<h3 class="nav-category__title">Reporting</h3>

			<div class="nav-category__links">
				<router-link automation-tag="statistics-and-metrics-menu-item" :to="{ name: AppRoutes.statistics }"
					>Statistics&Metrics</router-link
				>
				<router-link automation-tag="contact-sign-up-list-menu-item" :to="{ name: AppRoutes.eventContacts }"
					>Contact Sign Up List</router-link
				>
				<!-- <a class="disabled" title="Coming Soon!">Walk Campaign Reports</a> -->
				<!-- <a class="disabled" title="Coming Soon!">Phone Campaign Reports</a> -->
			</div>
		</section>

		<!-- <section class="nav-category">
			<h3 class="nav-category__title">Events Management</h3>

			<div class="nav-category__links">
				<a class="disabled" title="Coming Soon!">Create New Event</a>
				<a class="disabled" title="Coming Soon!">Manage Existing Events</a>
			</div>
		</section> -->

		<section class="nav-category">
			<h3 class="nav-category__title">Administration</h3>

			<div class="nav-category__links">
				<router-link automation-tag="manage-users-menu-item" :to="{ name: AppRoutes.manageUsers }">
					Manage Users
				</router-link>
				<router-link
					v-if="isAdmin || isCampaignManager"
					automation-tag="manage-events-menu-item"
					:to="{ name: AppRoutes.events }"
				>
					Manage Events
				</router-link>
				<router-link
					v-if="isAdmin || isCampaignManager"
					automation-tag="create-account-menu-item"
					:to="{ name: AppRoutes.addStaff }"
					>Add User</router-link
				>
				<router-link
					v-if="isAdmin || isCampaignManager"
					automation-tag="activism-portal-settings-menu-item"
					:to="{ name: AppRoutes.activismTiles }"
				>
					Activism Portal Settings
				</router-link>
				<router-link
					v-if="isPlatformAppsEnabled && (isAdmin || isCampaignManager)"
					automation-tag="platform-apps-menu-item"
					:to="{ name: AppRoutes.platformApps }"
				>
					Platform Apps
				</router-link>
				<router-link v-if="isAdmin" automation-tag="early-ballots-menu-item" :to="{ name: AppRoutes.earlyBallots }">
					Early Ballots
				</router-link>
			</div>
		</section>

		<a class="logout-link" href="#" automation-tag="logout-button" @click.prevent="onSignOutTap()">
			<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M7.5 8.5C8.88071 8.5 10 7.38071 10 6C10 4.61929 8.88071 3.5 7.5 3.5C6.11929 3.5 5 4.61929 5 6C5 7.38071 6.11929 8.5 7.5 8.5Z"
					stroke="#3C3E3F"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M3.22998 12.4C3.67622 11.6675 4.30339 11.0621 5.05119 10.6421C5.79899 10.222 6.64227 10.0013 7.49998 10.0013C8.35769 10.0013 9.20097 10.222 9.94877 10.6421C10.6966 11.0621 11.3237 11.6675 11.77 12.4"
					stroke="#3C3E3F"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z"
					stroke="#3C3E3F"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>

			<span>Log Out</span>
		</a>
	</nav>

	<SignOutPrompt :is-active="isPromptActive" @on-result="onPromptResult" />
</template>

<style lang="scss">
@import '@/scss/variables.scss';

nav {
	display: flex !important;
	flex-direction: column;
	overflow: auto;
	flex: 270px 0 0;
	background: $white;

	.logo-link {
		padding: 20px 20px 0;
		margin-bottom: 40px;

		img {
			max-width: 100%;
		}
	}

	.home-link {
		margin-bottom: 20px;
		height: 37px;
		flex: 37px 0 0;
		display: flex;
		align-items: center;
		display: flex;
		flex-direction: row;
		padding-left: 20px;
		text-decoration: none;
		color: $blue;
		font-size: 16px;

		span {
			margin-left: 10px;
		}

		&.router-link-exact-active {
			background: rgba(79, 124, 166, 0.3);
		}
	}

	.logout-link {
		display: flex;
		flex-direction: row;
		align-self: center;
		margin-top: auto;
		margin-bottom: 34px;
		color: $dark;
		text-decoration: none;

		span {
			margin-left: 10px;
		}
	}
}

.nav-category {
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;

	&__title {
		font-family: Montserrat-SemiBold;
		font-size: 18px;
		color: $dark-blue;
		text-transform: uppercase;
		margin-bottom: 10px;
		padding-left: 20px;
	}

	&__links {
		display: flex;
		flex-direction: column;

		a {
			padding-left: 20px;
			text-decoration: none;
			color: $blue;
			font-size: 16px;
			height: 29px;
			display: flex;
			align-items: center;
			margin: 3px 0;

			&.disabled {
				color: $bg-gray;
			}

			&.router-link-exact-active,
			&:not(.disabled):hover {
				background: $menu-active;
				color: $white;
			}
		}
	}
}
</style>
