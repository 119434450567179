import { ActionContext, CommitOptions, DispatchOptions, Store as VuexStore } from 'vuex'

import {
	ContactList,
	ContactListType,
	ContactListSearchFilter,
	LocalStoredContactsMatchedData
} from '@/api/contact-lists/models/ContactList'
import { VoterDatabase } from '@/api/contact-lists/models/VoterDatabase'
import { ContactsQuery } from '@/api/contact-lists/models/ContactsQuery'
import { Voter, VoterId } from '@/api/contact-lists/models/Voter'
import { NewVoterToList } from '@/api/contact-lists/models/NewVoterToList'
import ItemsResult from '@/api/models/ItemsResult'
import type { RootState } from '@/store/index'
import EventContact from '@/api/contact-lists/models/EventContact'
import PatchOperation from '@/api/models/PatchOperation'
import PaginationQueryGet from '@/api/models/PaginationQueryGet'
import EarlyBallotSchedule, {
	EarlyBallotScheduleResult,
	NewEarlyBallotSchedule
} from '@/api/contact-lists/models/EarlyBallotSchedule'

export interface ContactListsState {
	contactLists: ContactList[]
	matchingProgress: number
	isMatching: boolean
	totalContactsMatched: number
	matchingOperationId: string
	matchedContactListId: string
	contactListsCursor: { After: string; Before: string }
	voterDatabases: VoterDatabase[]
}

export enum ContactListsActionTypes {
	ADD_VOTERS_TO_LIST = 'ADD_VOTERS_TO_LIST',
	CREATE_CONTACT_LIST = 'CREATE_CONTACT_LIST',
	GET_CONTACT_LISTS = 'GET_CONTACT_LISTS',
	GET_CONTACT_LIST_BY_ID = 'GET_CONTACT_LIST_BY_ID',
	GET_ALL_VOTER_DATABASES = 'GET_ALL_VOTER_DATABASES',
	GET_CONTACT_LIST_VOTERS = 'GET_CONTACT_LIST_VOTERS',
	SEARCH_IN_VOTER_DATABASE = 'SEARCH_IN_VOTER_DATABASE',
	GET_EVENT_CONTACTS = 'GET_EVENT_CONTACTS',
	SEARCH_IN_VOTER_DATABASE_AND_RETURN_ALL = 'SEARCH_IN_VOTER_DATABASE_AND_RETURN_ALL',
	UPDATE_CONTACT_LIST = 'UPDATE_CONTACT_LIST',
	DELETE_CONTACTS = 'DELETE_CONTACTS',
	GET_AVAILABLE_POLITICAL_PARTIES = 'GET_AVAILABLE_POLITICAL_PARTIES',
	MATCH_CONTACTS_FROM_CSV = 'MATCH_CONTACTS_FROM_CSV',
	GET_MATCHED_DATA_FROM_LOCAL_STORAGE = 'GET_MATCHED_DATA_FROM_LOCAL_STORAGE',
	RESET_CONTACT_MATCHING = 'RESET_CONTACT_MATCHING',
	GET_EARLY_BALLOTS_SCHEDULES = 'GET_EARLY_BALLOTS_SCHEDULES',
	GET_EARLY_BALLOTS_NAMES = 'GET_EARLY_BALLOTS_NAMES',
	GET_STATES = 'GET_STATES',
	ADD_EARLY_BALLOT_TO_SCHEDULE = 'ADD_EARLY_BALLOT_TO_SCHEDULE',
	REMOVE_EARLY_BALLOT_FROM_SCHEDULE = 'REMOVE_EARLY_BALLOT_FROM_SCHEDULE',
	EDIT_EARLY_BALLOT_IN_SCHEDULE = 'EDIT_EARLY_BALLOT_IN_SCHEDULE'
}

export enum ContactListsMutationTypes {
	ADD_NEW_CONTACT_LIST = 'ADD_NEW_CONTACT_LIST',
	SET_CONTACT_LISTS = 'SET_CONTACT_LISTS',
	SET_CONTACT_LISTS_CURSOR = 'SET_CONTACT_LISTS_CURSOR',
	SET_VOTER_DATABASES = 'SET_VOTER_DATABASES',
	SET_MATCHING_STATUS = 'SET_MATCHING_STATUS',
	SET_MATCHING_PROGRESS = 'SET_MATCHING_PROGRESS',
	SET_MATCHING_CONTACT_LIST_ID = 'SET_MATCHING_CONTACT_LIST_ID',
	SET_MATCHING_OPERATION_ID = 'SET_MATCHING_OPERATION_ID',
	SET_MATCHED_CONTACTS_NUMBER = 'SET_MATCHED_CONTACTS_NUMBER'
}

export type Mutations<S = ContactListsState> = {
	[ContactListsMutationTypes.ADD_NEW_CONTACT_LIST](moduleState: S, newCandidateAccount: ContactList): void
	[ContactListsMutationTypes.SET_CONTACT_LISTS](
		moduleState: S,
		payload: { contactLists: ContactList[]; initial?: boolean }
	): void
	[ContactListsMutationTypes.SET_CONTACT_LISTS_CURSOR](moduleState: S, cursor: { After: string; Before: string }): void
	[ContactListsMutationTypes.SET_VOTER_DATABASES](moduleState: S, contactLists: VoterDatabase[]): void
	[ContactListsMutationTypes.SET_MATCHING_STATUS](moduleState: S, status: boolean): void
	[ContactListsMutationTypes.SET_MATCHING_PROGRESS](moduleState: S, progress: number): void
	[ContactListsMutationTypes.SET_MATCHING_CONTACT_LIST_ID](moduleState: S, id: string): void
	[ContactListsMutationTypes.SET_MATCHING_OPERATION_ID](moduleState: S, id: string): void
	[ContactListsMutationTypes.SET_MATCHED_CONTACTS_NUMBER](moduleState: S, contactsNumber: number): void
}

export type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<ContactListsState, RootState>, 'commit'>

export interface Actions {
	[ContactListsActionTypes.ADD_VOTERS_TO_LIST](
		{ commit, dispatch }: AugmentedActionContext,
		{
			candidateAccountId,
			contactListId,
			contacts
		}: { candidateAccountId: string; contactListId: string; contacts: NewVoterToList[] }
	): Promise<ContactList | null>
	[ContactListsActionTypes.CREATE_CONTACT_LIST](
		{ commit, rootState }: AugmentedActionContext,
		{
			candidateAccountId,
			contactListName,
			contactListType,
			searchFilter
		}: {
			candidateAccountId: string
			contactListName: string
			contactListType: ContactListType
			searchFilter?: ContactListSearchFilter
		}
	): Promise<ContactList | null>
	[ContactListsActionTypes.GET_CONTACT_LISTS](
		{ commit, dispatch, state }: AugmentedActionContext,
		candidateAccountId: string
	): void
	[ContactListsActionTypes.GET_CONTACT_LIST_BY_ID](
		{ commit, dispatch, state }: AugmentedActionContext,
		{ candidateAccountId, contactListId }: { candidateAccountId: string; contactListId: string }
	): Promise<ContactList | null>
	[ContactListsActionTypes.GET_CONTACT_LIST_VOTERS](
		{ commit, dispatch }: AugmentedActionContext,
		{
			candidateAccountId,
			contactListId,
			pagination
		}: { candidateAccountId: string; contactListId: string; pagination: PaginationQueryGet }
	): Promise<ItemsResult<Voter>>
	[ContactListsActionTypes.GET_ALL_VOTER_DATABASES]({ commit, dispatch }: AugmentedActionContext): void
	[ContactListsActionTypes.SEARCH_IN_VOTER_DATABASE](
		{ commit, dispatch }: AugmentedActionContext,
		{ databaseId, query }: { databaseId: string; query: ContactsQuery }
	): Promise<ItemsResult<Voter>>
	[ContactListsActionTypes.GET_EVENT_CONTACTS](
		{ commit, dispatch }: AugmentedActionContext,
		{ candidateAccountId }: { candidateAccountId: string }
	): Promise<ItemsResult<EventContact>>
	[ContactListsActionTypes.SEARCH_IN_VOTER_DATABASE_AND_RETURN_ALL](
		{ commit, dispatch }: AugmentedActionContext,
		{ databaseId, query }: { databaseId: string; query: ContactsQuery }
	): Promise<VoterId[]>
	[ContactListsActionTypes.UPDATE_CONTACT_LIST](
		{ commit, rootState }: AugmentedActionContext,
		{
			candidateAccountId,
			contactListId,
			operations
		}: { candidateAccountId: string; contactListId: string; operations: PatchOperation[] }
	): Promise<ContactList | null>
	[ContactListsActionTypes.DELETE_CONTACTS](
		{ commit, rootState }: AugmentedActionContext,
		{
			candidateAccountId,
			contactListId,
			contactIds,
			deleteAll
		}: { candidateAccountId: string; contactListId: string; contactIds?: string[]; deleteAll?: boolean }
	): Promise<boolean>
	[ContactListsActionTypes.GET_AVAILABLE_POLITICAL_PARTIES](): Promise<String[]>

	[ContactListsActionTypes.MATCH_CONTACTS_FROM_CSV](
		{ commit, rootState }: AugmentedActionContext,
		{ candidateAccountId, uploadedCSVUrl }: { candidateAccountId: string; uploadedCSVUrl: string }
	): void

	[ContactListsActionTypes.GET_MATCHED_DATA_FROM_LOCAL_STORAGE](
		{ commit, state }: AugmentedActionContext,
		data: LocalStoredContactsMatchedData
	): void

	[ContactListsActionTypes.RESET_CONTACT_MATCHING]({ commit, rootState }: AugmentedActionContext): void

	[ContactListsActionTypes.GET_EARLY_BALLOTS_SCHEDULES](): Promise<EarlyBallotScheduleResult>

	[ContactListsActionTypes.GET_EARLY_BALLOTS_NAMES](_: AugmentedActionContext, stateName: string): Promise<string[]>

	[ContactListsActionTypes.GET_STATES](): Promise<string[]>

	[ContactListsActionTypes.ADD_EARLY_BALLOT_TO_SCHEDULE](
		_: AugmentedActionContext,
		payload: NewEarlyBallotSchedule
	): Promise<EarlyBallotSchedule | null>

	[ContactListsActionTypes.REMOVE_EARLY_BALLOT_FROM_SCHEDULE](
		_: AugmentedActionContext,
		{ stateName, scheduleId }: { stateName: string; scheduleId: string }
	): Promise<boolean>

	[ContactListsActionTypes.EDIT_EARLY_BALLOT_IN_SCHEDULE](
		_: AugmentedActionContext,
		{ stateName, scheduleId, payload }: { stateName: string; scheduleId: string; payload: EarlyBallotSchedule }
	): Promise<boolean>
}

export type Getters<State = ContactListsState> = {
	isContactListsAllLoaded(moduleState: State): boolean
}

export type ContactListsStore<S = ContactListsState> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'> & {
	commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
		key: K,
		payload: P,
		options?: CommitOptions
	): ReturnType<Mutations[K]>
} & {
	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
} & {
	getters: {
		[K in keyof Getters]: ReturnType<Getters[K]>
	}
}
