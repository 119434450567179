import * as Sentry from '@sentry/vue'
import {
	signInWithEmailAndPassword,
	onAuthStateChanged,
	sendPasswordResetEmail,
	confirmPasswordReset,
	AuthErrorCodes
} from 'firebase/auth'
import { ActionTree, MutationTree, GetterTree, Module } from 'vuex'

import { CandidateAccountUsersApi } from '@/api/candidate-account'
import type { RootState } from '@/store/index'
import { CandidateAccountActionTypes } from '@/store/modules/candidate-account/types'
import logger from '@/services/log-service'
import { getFirebaseAuth, fetchAndActivateRemoteConfig } from '@/utils/firebase'
import { showErrorMessage } from '@/utils/toast-message'

import {
	AuthState,
	Actions,
	AuthActionTypes,
	AuthMutationTypes,
	AuthGettersType,
	Mutations,
	Getters,
	CustomAuthErrorCodes,
	CustomAuthErrorMessages
} from './types'

export const authState: AuthState = {
	user: null,
	isAuthorized: false,
	authErrorCode: '',
	onAuthStateChangedUnsubscribe: null
}

const actions: ActionTree<AuthState, RootState> & Actions = {
	async [AuthActionTypes.LOGIN]({ dispatch }, { email, password, candidateAccount }) {
		try {
			dispatch(AuthActionTypes.HANDLE_FIREBASE_ERROR, '')

			const auth = getFirebaseAuth(candidateAccount.FirebaseAccount)

			await signInWithEmailAndPassword(auth, email, password)

			await dispatch(CandidateAccountActionTypes.SELECT_CANDIDATE_ACCOUNT, candidateAccount, { root: true })

			dispatch(AuthActionTypes.CHECK_AUTHENTICATION) // we're not waiting for the completion here – this is expected
		} catch (error) {
			logger.error('Something went wrong while login in firebase', error)

			dispatch(AuthActionTypes.HANDLE_FIREBASE_ERROR, error)
		}
	},

	async [AuthActionTypes.SEND_RESET_PASSWORD_LINK]({ dispatch }, { email, candidateAccount }) {
		try {
			const auth = getFirebaseAuth(candidateAccount.FirebaseAccount)

			await sendPasswordResetEmail(auth, email)
		} catch (error) {
			dispatch(AuthActionTypes.HANDLE_FIREBASE_ERROR, error)
		}
	},

	async [AuthActionTypes.RESET_PASSWORD]({ dispatch }, { code, password, firebaseAppName }) {
		try {
			dispatch(AuthActionTypes.HANDLE_FIREBASE_ERROR, '')

			const auth = getFirebaseAuth(firebaseAppName)

			await confirmPasswordReset(auth, code, password)

			return true
		} catch (error) {
			logger.error('Something went wrong while reset password', error)

			dispatch(AuthActionTypes.HANDLE_FIREBASE_ERROR, error)

			return false
		}
	},

	[AuthActionTypes.HANDLE_FIREBASE_ERROR]({ commit }, { code }) {
		if (code) logger.warn(`Error code: ${code}`)

		let finalErrorMessage = ''

		switch (true) {
			case code === AuthErrorCodes.USER_DELETED:
				finalErrorMessage = CustomAuthErrorMessages.UserNotFound
				break
			case code === AuthErrorCodes.INVALID_PASSWORD:
			case code === AuthErrorCodes.INVALID_LOGIN_CREDENTIALS:
				finalErrorMessage = CustomAuthErrorMessages.WrongPassword
				break
			case code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
				finalErrorMessage = CustomAuthErrorMessages.TooManyRequests
				break
			case code === AuthErrorCodes.EXPIRED_OOB_CODE:
				finalErrorMessage = CustomAuthErrorMessages.ExpiredActionCode
				break
			case code === CustomAuthErrorCodes.CANDIDATES_NOT_FOUND:
				finalErrorMessage = CustomAuthErrorMessages.CandidatesNotFound
				break
			case code === CustomAuthErrorCodes.NO_FIREBASE_APP:
				finalErrorMessage = CustomAuthErrorMessages.NoFirebaseApp
				break
			default:
				finalErrorMessage = code
		}

		commit(AuthMutationTypes.SET_AUTH_ERROR_CODE, finalErrorMessage)
	},

	async [AuthActionTypes.LOG_OUT]({ dispatch, rootState }) {
		const { selectedCandidateAccount } = rootState.candidateAccount

		if (selectedCandidateAccount == null) {
			return
		}

		const firebaseAppName = rootState.candidateAccount.selectedCandidateAccount?.FirebaseAccount

		if (!firebaseAppName) {
			return
		}

		try {
			await getFirebaseAuth(firebaseAppName).signOut()

			await dispatch(CandidateAccountActionTypes.CLEAR_CANDIDATE_ACCOUNTS, {}, { root: true })
		} catch (error) {
			logger.error('Something went wrong while logout in firebase', error)

			dispatch(AuthActionTypes.HANDLE_FIREBASE_ERROR, error)
		}
	},

	[AuthActionTypes.CHECK_AUTHENTICATION]({ commit, dispatch, state, rootState }) {
		const firebaseAppName = rootState.candidateAccount.selectedCandidateAccount?.FirebaseAccount

		if (!firebaseAppName) {
			showErrorMessage("It looks there's an issue while checking your sign-in state")

			Sentry.captureMessage('CHECK_AUTHENTICATION - firebaseAppName is not available')

			return
		}

		try {
			const auth = getFirebaseAuth(firebaseAppName)

			if (state.onAuthStateChangedUnsubscribe) {
				state.onAuthStateChangedUnsubscribe()
			}

			const unsubscribe = onAuthStateChanged(auth!, async (firebaseUser) => {
				if (firebaseUser?.emailVerified) {
					await dispatch(AuthActionTypes.GET_USER_INFO)

					await fetchAndActivateRemoteConfig()

					commit(AuthMutationTypes.SET_AUTHORIZED_STATUS, true)

					Sentry.setUser({
						id: firebaseUser.uid,
						email: firebaseUser.email || undefined
					})
				} else {
					commit(AuthMutationTypes.SET_USER, null)

					commit(AuthMutationTypes.SET_AUTHORIZED_STATUS, false)

					Sentry.setUser(null)
				}
			})

			commit(AuthMutationTypes.SET_ON_AUTH_STATE_CHANGED_UNSUBSCRIBE, unsubscribe)
		} catch (e) {
			commit(AuthMutationTypes.SET_USER, null)

			commit(AuthMutationTypes.SET_AUTHORIZED_STATUS, false)

			showErrorMessage(e)

			Sentry.captureException(e)
		}
	},

	async [AuthActionTypes.GET_USER_INFO]({ commit, rootState }) {
		try {
			const { selectedCandidateAccount } = rootState.candidateAccount

			if (selectedCandidateAccount == null) {
				return null
			}

			const user = await CandidateAccountUsersApi.getMe(selectedCandidateAccount.Id)

			commit(AuthMutationTypes.SET_USER, user)

			return user
		} catch (error) {
			logger.error('Something went wrong while fetching user info', error)

			showErrorMessage(error)

			return null
		}
	}
}

const mutations: MutationTree<AuthState> & Mutations = {
	[AuthMutationTypes.SET_USER](moduleState, user) {
		moduleState.user = user
	},

	[AuthMutationTypes.SET_AUTHORIZED_STATUS](moduleState, status) {
		moduleState.isAuthorized = status
	},

	[AuthMutationTypes.SET_AUTH_ERROR_CODE](moduleState, code) {
		moduleState.authErrorCode = code
	},

	[AuthMutationTypes.SET_ON_AUTH_STATE_CHANGED_UNSUBSCRIBE](moduleState, onAuthStateChangedUnsubscribe) {
		moduleState.onAuthStateChangedUnsubscribe = onAuthStateChangedUnsubscribe
	}
}

export const getters: GetterTree<AuthState, RootState> & Getters = {
	[AuthGettersType.GET_AUTH_STATUS](moduleState) {
		return moduleState.isAuthorized
	},

	[AuthGettersType.GET_USER_NAME](moduleState) {
		if (moduleState.user) {
			return moduleState.user.FirstName
		}
		return null
	},

	[AuthGettersType.GET_FULL_USER_NAME](moduleState) {
		if (moduleState.user) {
			return `${moduleState.user.FirstName} ${moduleState.user.LastName}`
		}
		return null
	},

	[AuthGettersType.GET_CURRENT_USER_ID](moduleState) {
		return moduleState.user?.Id as string
	},

	[AuthGettersType.GET_USER_ROLE](moduleState) {
		return moduleState.user?.Role ?? null
	}
}

export const store: Module<AuthState, RootState> = {
	state: authState,
	getters,
	mutations,
	actions
}
